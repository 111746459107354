export function mansionType(mansionId) {
  const mansionTypeNumber = getSixthDigit(mansionId);
  const result = {};
  result.Number = mansionTypeNumber;
  result.isMansionBundleCall = mansionTypeNumber == "0";
  result.isMansionIndividualCall = mansionTypeNumber == "1";
  result.isDetachedHouse = mansionTypeNumber == "2";
  return result;
}

function getSixthDigit(mansionId) {
  // mansionIdが未定義の場合、"99"を返す。
  if (mansionId === undefined) {
    return "99";
  }
  return mansionId.charAt(5);
}