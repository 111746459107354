import { Section } from "./Section";
import {
  InputTitle,
  InputText,
  InputTextarea,
  InputNumber,
  InputReadOnly,
  InputRadio,
  Select
} from "./FormParts";
import { prefecturesMaster } from "../utility/prefecturesMaster";
import style from "../style/style.module.css";

export const BasicInfoSection = ({
  postalCode,
  state,
  city,
  addressLine1,
  addressLine2,
  mansionNameJapanese,
  buildingNameJapanese,
  roomName,
  formData,
  setValue,
  usageClassificationIdOptions,
  validError,
  isDetachedHouse,
  isEntered,
  responseData
}) => {

  return (
    <Section title="基本情報">
      <EntryProperty
        isDetachedHouse={isDetachedHouse}
        mansionNameJapanese={mansionNameJapanese}
        buildingNameJapanese={buildingNameJapanese}
        roomName={roomName}
      />
      <EntryAddressInfo
        setValue={setValue}
        validError={validError}
        formData={formData}
        isEntered={isEntered}
        isDetachedHouse={isDetachedHouse}
      />
      <InputTitle
        title="エントリー代表者名"
        required
      />
      <p className={style["text-blue"]}>電気契約者ご本人のお名前</p>
      <div className={style["input-lastname-wrap"]}>
        <span className={style["text-blue"]}>姓</span>
        <InputText
          setValue={setValue}
          value={formData.representativeLastName ?? ""}
          name="representativeLastName"
          maxlength={64}
          validError={validError}
        />
      </div>
      <div className={style["input-firstname-wrap"]}>
        <span className={style["text-blue"]}>名</span>
        <InputText
          setValue={setValue}
          value={formData.representativeFirstName ?? ""}
          name="representativeFirstName"
          maxlength={64}
          validError={validError}
        />
      </div>
      <p className={style["representative-name-attention"]}>ご本人確認結果によっては、くらしのサス活に参加できない場合があります。</p>
      <InputTitle
        title="生年月日"
        required
      />
      <p className={style["text-blue"]}>本人確認に使用させていただくことがあります。</p>
      <div className={style["input-birth-wrap"]}>
        <InputNumber
          placeholder="1990"
          setValue={setValue}
          value={formData.representativeBirthYear ?? ""}
          name="representativeBirthYear"
          maxlength={4}
          validError={validError}
        />
        <span className={style["text-blue"]}>年</span>
        <InputNumber
          placeholder="12"
          setValue={setValue}
          value={formData.representativeBirthMonth ?? ""}
          name="representativeBirthMonth"
          maxlength={2}
          validError={validError}
        />
        <span className={style["text-blue"]}>月</span>
        <InputNumber
          placeholder="30"
          setValue={setValue}
          value={formData.representativeBirthDay ?? ""}
          name="representativeBirthDay"
          maxlength={2}
          validError={validError}
        />
        <span className={style["text-blue"]}>日</span>
      </div>
      {
        isEntered !== 1 &&
        <UsageDateInfo
          setValue={setValue}
          validError={validError}
          formData={formData}
        />
      }
      <InputTitle
        title="居住者"
        required
      />
      <div className={style["radio-usage-classification-wrap"]}>
        <InputRadio
          options={usageClassificationIdOptions}
          defaultValue={String(formData.usageClassificationId ?? 1)}
          name="usageClassificationId"
          setValue={setValue}
        />
      </div>
      <InputTitle
        title="主に居住する人数"
      />
      <div className={style["input-residents-wrap"]}>
        <p className={style["label"]}>世帯人数</p>
        <Select
          options={[
            { value: "1", label: "1" },
            { value: "2", label: "2" },
            { value: "3", label: "3" },
            { value: "4", label: "4" },
            { value: "5", label: "5" },
            { value: "6", label: "6" },
            { value: "7", label: "7" },
            { value: "8", label: "8" },
            { value: "9~", label: "9~" }
          ]}
          placeholder="0"
          defaultValue={formData.residentsCount ?? ""}
          setValue={setValue}
          name="residentsCount"
          validError={validError}
        />
        <p className={style["unit"]}>人</p>
      </div>
      <div className={style["input-child-residents-wrap"]}>
        <p className={style["label"]}>うち18歳未満</p>
        <Select
          options={[
            { value: "0", label: "0" },
            { value: "1", label: "1" },
            { value: "2", label: "2" },
            { value: "3", label: "3" },
            { value: "4", label: "4" },
            { value: "5", label: "5" },
            { value: "6", label: "6" },
            { value: "7", label: "7" },
            { value: "8", label: "8" },
            { value: "9~", label: "9~" }
          ]}
          placeholder="0"
          defaultValue={formData.childResidentsCount ?? ""}
          setValue={setValue}
          name="childResidentsCount"
          validError={validError}
        />
        <p className={style["unit"]}>人</p>
      </div>
      {
        responseData["roomSize"] === null &&
        <RoomSize
          setValue={setValue}
          validError={validError}
          formData={formData}
          isDetachedHouse={isDetachedHouse}
        />
      }
    </Section>
  );
}

const UsageDateInfo = ({ setValue, validError, formData }) => {
  const usageDateFlgBool = formData.usageDateFlg == "true";
  return (
    <>
      <InputTitle
        title="入居予定日"
        required
      />
      <div className={style["input-movein-radio-wrap"]}>
        <InputRadio
          options={[{ label: "居住中", value: "false" }, { label: "未入居（入居予定日をご入力ください）", value: "true" }]}
          defaultValue={formData.usageDateFlg ?? "false"}
          name="usageDateFlg"
          validError={validError}
          setValue={setValue}
        />
      </div>
      <div className={style["input-movein-wrap"]}>
        <InputNumber
          placeholder="2024"
          value={formData.usageFromYear ?? ""}
          name="usageFromYear"
          maxlength={4}
          setValue={setValue}
          validError={validError}
          disabled={!usageDateFlgBool}
        />
        <span className={style["text-blue"]}>年</span>
        <InputNumber
          placeholder="12"
          value={formData.usageFromMonth ?? ""}
          name="usageFromMonth"
          maxlength={2}
          setValue={setValue}
          validError={validError}
          disabled={!usageDateFlgBool}
        />
        <span className={style["text-blue"]}>月</span>
        <InputNumber
          placeholder="30"
          value={formData.usageFromDay ?? ""}
          name="usageFromDay"
          maxlength={2}
          setValue={setValue}
          validError={validError}
          disabled={!usageDateFlgBool}
        />
        <span className={style["text-blue"]}>日</span>
      </div>
    </>
  );
}

const EntryAddressInfo = ({ setValue, validError, formData, isEntered, isDetachedHouse }) => {
  const isEntryCompleted = isEntered == 1;

  return (
    <>
      <InputTitle
        title="エントリー物件住所"
        required={!isEntryCompleted}
      />
      <p className={style["text-blue"]}>お住まいの住所を入力してください。</p>
      <div className={style["input-entry-zip-wrap"]}>
        <span className={style["label"]}>〒</span>
        <InputText
          setValue={setValue}
          name="entryZip"
          validError={validError}
          placeholder="1030022"
          value={formData["entryZip"] ?? ""}
          disabled={isEntryCompleted}
        />
      </div>
      <div className={style["input-entry-state-wrap"]}>
        <span className={style["label"]}>住所</span>
        <Select
          options={prefecturesMaster()}
          setValue={setValue}
          name="entryState"
          validError={validError}
          placeholder="ーーー"
          defaultValue={formData["entryState"] ?? ""}
          disabled={isEntryCompleted}
        />
      </div>
      <div className={style["input-entry-address-wrap"]}>
        <span className={style["label"]}></span>
        <InputTextarea
          setValue={setValue}
          name="entryAddress"
          validError={validError}
          placeholder="中央区日本橋橋室町3-2-1"
          value={formData["entryAddress"] ?? ""}
          disabled={isEntryCompleted}
        />
      </div>
      {isDetachedHouse && (
        <div>
          <p className={style["detached-house-attention"]}>集合住宅の方は「マンション名」まで正しく入力してください。</p>
        </div>
      )}
    </>
  );
};

const RoomSize = ({ setValue, validError, formData, isDetachedHouse }) => {
  const roomSizeTitle = isDetachedHouse ? "専有面積･延床面積" : "専有面積";
  return (
    <>
      <InputTitle
        title={roomSizeTitle}
        required
      />
      <div className={style["input-room-size-wrap"]}>
        <InputNumber
          placeholder={isDetachedHouse ? "100": "70"}
          name="roomSize"
          validError={validError}
          setValue={setValue}
          value={formData["roomSize"]}
        />
        <span className={style["unit"]}>㎡</span>
        {isDetachedHouse ? (
          <div className={style["example"]}>
            <span>例：戸建て 4LDK 100㎡<br />　　集合住宅 3LDK 70㎡</span>
          </div>
        ): (
          <div className={style["example"]}>
            <span>例：3LDK　70㎡</span>
          </div>
        )}
      </div>
    </>
  );
}

const EntryProperty = ({ isDetachedHouse, mansionNameJapanese, buildingNameJapanese, roomName }) => {
  const inputValue = isDetachedHouse ? `${mansionNameJapanese}\n${roomName}` : `${mansionNameJapanese}${buildingNameJapanese}\n${roomName}`;
  return (
    <>
      <InputTitle title="エントリー物件" />
      {
        isDetachedHouse && <p className={style["text-blue"]}>6桁の数字は、お客様番号です</p>
      }
      <InputReadOnly
        defaultValue={inputValue}
        fullWidth
      />
    </>
  );
}