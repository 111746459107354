import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { evaluationIcons } from "../../../utility/mapping.js";
import logoSus from "../image/logo_sus.svg";
import logoMitsui from "../image/logo_mitsui.svg";
import hamburger from "../image/icon_menu.svg";
import menuClose from "../image/menu_close.svg";
import over from "../image/icon_over.svg";
import pBlue from "../image/icon_p_blue.svg";
import triangle from "../image/icon_triangle_right.svg";
import redArrowCircle from "../image/icon_button_arrow_red.svg";
import blueArrowCircle from "../image/icon_button_arrow_blue.svg";
import startGuide from "../image/startguide_graph.png";
import style from "../style/header.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";

/**
 * ホーム画面ヘッダー
 */

export const Header = ({ formData }) => {

  // ハンバーガーメニューの開閉
  const [isOpen, setIsOpen] = useState(false);

  // 一定量スクロールされたら、いくつかのヘッダーの要素を表示させる
  const [isScrolled, setIsScrolled] = useState(false);

  const SCROLL_POINT = 500;

  const checkScroll = () => {
    if (window.scrollY > SCROLL_POINT) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
  }, []);

  return (
    <>
      <header className={style["header"]}>
        <div className={style["header-inner"]}>
          <div className={style["header-wrap"]}>
            <img src={logoMitsui} alt="logo-mitsui" />
            {
              isScrolled &&
              <img src={logoSus} alt="logo-sus" className={style["sus-logo"]} />
            }
            {
              isScrolled && formData.isEntered === 1 &&
              <p className={style["header-point-scrolled"]}>
                <img src={pBlue} alt="" />
                {formData.totalValidPoint && formData.totalValidPoint.toLocaleString("ja-JP")}
                <span className={style["header-point-unit-scrolled"]}>pt</span>
              </p>
            }
          </div>
          {
            isOpen
              ? <img
                src={menuClose}
                className={style["hamburger-close"]}
                onClick={() => {
                  setIsOpen(false);
                }}
              />
              : <img
                src={hamburger}
                className={style["hamburger-open"]}
                onClick={() => {
                  setIsOpen(true);
                }}
              />
          }
          <HamburgerMenu isOpen={isOpen} setIsOpen={setIsOpen} formData={formData} />
        </div>
      </header>
      {formData.isEntered === 0 && <SubHeaderBeforeEntry />}
      {formData.isEntered === 2 && <SubHeaderDuringEntry formData={formData} />}
      {formData.isEntered === 1 && <SubHeaderAfterEntry formData={formData} />}
    </>
  );
}

const SubHeaderBeforeEntry = () => {
  const ENTRY_REWARD_POINTS = 3000;

  return (
    <div>
      <div className={style["entry-flow"]}>
        <div className={style["entry-circle-red"]}>
          <p className={style["entry-circle-text"]}>エントリー</p>
        </div>
        <img src={triangle} alt="triangle" />
        <div className={style["entry-circle"]}>
          <p className={style["entry-circle-text"]}>エントリー</p>
          <p className={style["entry-circle-text"]}>完了</p>
        </div>
        <img src={triangle} alt="triangle" />
        <div className={style["entry-circle"]}>
          <p className={style["entry-circle-text"]}>省エネ</p>
          <p className={style["entry-circle-text"]}>ポイント</p>
          <p className={style["entry-circle-text"]}>獲得</p>
        </div>
      </div>
      <div className={style["start-guide-before-entry"]}>
        <div>
          <p className={style["start-guide-text-white"]}>くらしのサス活&emsp;スタートガイド</p>
        </div>
        <div className={style["start-guide-margin"]}>
          <div className={style["start-guide-inner"]}>
            <div className={style["start-guide-inner-guide"]}>
              <p className={style["start-guide-text-blue"]}>エントリーと<br />電力データ連携手続きをすませて</p>
              <p className={style["start-guide-text-red"]}>
                <span className={style["start-guide-point-red"]}>{ENTRY_REWARD_POINTS.toLocaleString("ja-JP")}</span>
                <span className={style["start-guide-text-red"]}>ptを獲得</span>
                <span className={style["start-guide-text-blue"]}>しよう！</span>
              </p>
              <div className={style["start-guide-button"]}>
                <EntryLinkButton />
              </div>
            </div>
            <div className={style["start-guide-rule"]}>
              <p className={style["start-guide-text-blue"]}>サス活に取り組んで</p>
              <p className={style["start-guide-text-blue"]}>
                <span className={style["start-guide-text-red"]}>省エネポイントを獲得</span>
                しよう！
              </p>
              <div className={style["start-guide-border"]}></div>
              <div className={style["start-guide-rule-detail"]}>
                <p className={style["start-guide-text-gray"]}>毎月の電気・ガス使用量をCO&#8322;換算し、CO&#8322;削減量に応じてポイントが付与されます。</p>
                <p className={style["start-guide-text-gray"]}>ポイント付与は翌月末です。</p>
              </div>
            </div>
            <div className={style["start-guide-image-area"]}>
              <img src="https://service.appbox.jp/kurasus/img/webp/sus-katsu-list/img-suskatsu.webp" alt="startGuide" />
            </div>
            <div>
              <div>
                <span className={style["start-guide-text-strong-point"]}>{ENTRY_REWARD_POINTS.toLocaleString("ja-JP")}</span>
                <span className={style["start-guide-text-strong-blue"]}>pt</span>
                <span className={style["start-guide-text-small-gray"]}>獲得まで</span>
                <span className={style["start-guide-text-strong-gray"]}>あと少し！</span>
              </div>
              <EntryLinkButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const SubHeaderDuringEntry = ({ formData }) => {
  return (
    <div className={style["sub-header"]}>
      <div className={style["entry-flow"]}>
        <div className={style["entry-circle-blue"]}>
          <p className={style["entry-circle-text"]}>エントリー</p>
        </div>
        <img src={triangle} alt="triangle" />
        <div className={style["entry-circle-red"]}>
          <p className={style["entry-circle-text"]}>エントリー</p>
          <p className={style["entry-circle-text"]}>完了</p>
        </div>
        <img src={triangle} alt="triangle" />
        <div className={style["entry-circle"]}>
          <p className={style["entry-circle-text"]}>省エネ</p>
          <p className={style["entry-circle-text"]}>ポイント</p>
          <p className={style["entry-circle-text"]}>獲得</p>
        </div>
      </div>
      <div className={style["start-guide"]}>
        <div className={style["start-guide-margin"]}>
          <div className={style["start-guide-inner"]}>
            <div className={style["start-guide-inner-guide"]}>
              <p className={style["start-guide-text-blue"]}>
                エネルギーデータ連携<span className={style["start-guide-text-red"]}>準備中</span>です
              </p>
              <div className={style["start-guide-border"]}></div>
              <p className={style["start-guide-text-gray"]}>初回の省エネポイント付与まで最長3ヶ月かかります。</p>
            </div>
            <div className={style["note"]}>
              <p className={style["note-text"]}>
                ・3ヶ月経過してもこの表示の場合は<Link to="/secretariatContact">事務局へお問い合わせ</Link>ください。
              </p>
              {(formData.roomParentChildType === "parent" && formData.mansionId.substring(5,6) !== "0") && (
                <p className={style["note-text"]}>
                  ・電力データ提供のお手数きを終えていない方、エラー等で再実施される方は<Link to="/procedure">こちら</Link>から。
                </p>
              )}
            </div>
            <div div className={style["start-guide-inner-change"]}>
              <p className={style["start-guide-text-blue"]}>
                ポイントを<span className={style["start-guide-text-red"]}>どんどん交換</span>しよう!
              </p>
              <div className={style["start-guide-border"]}></div>
              <p className={style["start-guide-text-gray"]}>ポイントの有効期限は「ポイント履歴」より確認できます。ぜひ期限までに交換してお楽しみください。</p>
            </div>
            <RuleLinkButton />
          </div>
        </div>
      </div>
      <Banner formData={formData} />
      <div className={style["sus-logo-area"]}>
        <div className={style["sus-logo-area-inner"]}>
          <img src={logoSus} alt="logo-sus" />
          <div className={style["sus-logo-area-right"]}>
            <Link to="/pointHistory" className={style["link-point"]}>
              保有ポイント
              <span className={style["point"]}>
                {formData.totalValidPoint && formData.totalValidPoint.toLocaleString("ja-JP")}
                <span className={style["unit"]}>pt</span>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

const SubHeaderAfterEntry = ({ formData }) => {
  // 月別削減CO2差分値の算出
  const monthlyCo2EmissionDifference = formData.monthlyCo2EmissionPerformance - formData.monthlyCo2EmissionTarget;

  // 活動結果の表示月の算出
  // iOS対応のため、"/1"を加えてDataオブジェクトを生成する
  // nullの場合は空文字を当てる
  const nearestExpirationMonth = formData.nearestPointGrantDate === null
    ? ""
    : new Date(`${formData.nearestPointGrantDate}/1`).getMonth() + 1;

  // 直近有効期限切れポイント情報の表示フラグ
  const showNearestExpirationInfo
    = formData.nearestExpirationDate !== null
    && formData.pointsByExpirationDate !== 0
    && formData.pointsByExpirationDate !== null;

  return (
    <div className={style["sub-header"]}>
      <Banner formData={formData} />
      <div className={style["sus-logo-area"]}>
        <div className={style["sus-logo-area-inner-after-entry"]}>
          <img src={logoSus} alt="logo-sus" />
          <div className={style["sus-logo-area-right"]}>
            <Link to="/pointHistory" className={style["link-point"]}>
              保有ポイント
              <span className={style["point"]}>
                {formData.totalValidPoint && formData.totalValidPoint.toLocaleString("ja-JP")}
                <span className={style["unit"]}>pt</span>
              </span>
            </Link>
            {
              showNearestExpirationInfo &&
              <p className={style["point-expiration"]}>
                {formData.nearestExpirationDate}末に{formData.pointsByExpirationDate.toLocaleString("ja-JP")}pt期限切れ
              </p>
            }
          </div>
        </div>
      </div>
      <div className={style["activity-result-wrap"]}>
        <p className={style["activity-result-title"]}>
          {nearestExpirationMonth}月の活動結果
        </p>
        <a href="/energy" className={style["activity-result-link"]}>
          <div className={style["activity-result-item"]}>
            <p className={style["activity-result-item-title"]}>想定CO&#8322;排出量</p>
            <p className={style["activity-result-item-data"]}>
              <span>
                {formData.monthlyCo2EmissionPerformance && formData.monthlyCo2EmissionPerformance.toLocaleString("ja-JP")}
                <span className={style["unit"]}>kg</span>
              </span>
            </p>
          </div>
          <div className={style["activity-result-item"]}>
            <p className={style["activity-result-item-title"]}>想定CO&#8322;削減量</p>
            <p className={style["activity-result-item-data"]}>
              {
                monthlyCo2EmissionDifference == 0
                  ? null
                  : monthlyCo2EmissionDifference > 0
                    ? <img src={over} alt="icon" className={style["activity-result-item-icon"]} />
                    : <img src={null} className={style["activity-result-item-icon"]} />
              }
              <span>
                {Math.abs(monthlyCo2EmissionDifference).toLocaleString("ja-JP")}
                <span className={style["unit"]}>kg</span>
              </span>
            </p>
          </div>
          <div className={style["activity-result-item"]}>
            <p className={style["activity-result-item-title"]}>獲得ポイント</p>
            <p className={`${style["activity-result-item-data"]} ${style["point"]}`}>
              <img
                src={
                  evaluationIcons?.[formData.nearestEvaluationStatus]
                }
                alt=""
                className={style["activity-result-item-icon"]}
              />
              <span>
                {formData.nearestGrantedPoint && formData.nearestGrantedPoint.toLocaleString("ja-JP")}
                <span className={style["unit"]}>pt</span>
              </span>
            </p>
          </div>
        </a>
      </div>
    </div>
  );
}

const HamburgerMenu = ({ isOpen, setIsOpen, formData }) => {
  return (
    <div
      className={`${style["hamburger"]} ${isOpen ? style["open"] : ""}`}
      onClick={() => setIsOpen(false)}
    >
      <div
        className={style["hamburger-wrap"]}
        onClick={e => e.stopPropagation()}
      >
        <img src={logoSus} alt="logoSus" className={style["hamburger-logo-sus"]} />
        <div className={style["menu-wrap"]}>
          <Link to="https://service.appbox.jp/kurasus/sus-katsu-list/" className={style["menu-link"]}>「くらしのサス活」とは？</Link>
          {formData.isEntered !== 0 && <Link to="/energy" className={style["menu-link"]}>あなたの想定CO&#8322;排出量</Link>}
          <Link to="/list" className={style["menu-link"]}>特典一覧</Link>
          {formData.isEntered !== 0 && <Link to="/pointHistory" className={style["menu-link"]}>ポイント履歴</Link>}
          {formData.isEntered !== 0 && <Link to="/pointEarning" className={style["menu-link"]}>コードでポイント獲得</Link>}
          {formData.isEntered !== 0 && <Link to="/entry" className={style["menu-link"]}>エントリー情報確認・変更</Link>}
          {formData.isEntered !== 0 && <Link to="/secretariatContact" className={style["menu-link"]}>事務局お問い合わせ</Link>}
          <Link to="https://service.appbox.jp/kurasus/kiyaku/" className={style["menu-link"]}>くらしのサス活規約</Link>
          <Link to="https://service.appbox.jp/kurasus/policy/" className={style["menu-link"]}>個人情報の取扱いについて等</Link>
        </div>
      </div>
    </div>
  );
}

const Banner = ({ formData }) => {
  const itemList = formData.bannerList && formData.bannerList.map((item, index) => {
    return (
      <SwiperSlide key={index}>
        <a href={item.url}>
          <img src={item.image} className={style["banner"]} alt="" />
        </a>
      </SwiperSlide>
    );
  })

  return (
    <>
      <Swiper
        modules={[Autoplay]}
        slidesPerView={1}
        loop={true}
        autoHeight={true}
        autoplay={{
          delay: 4000
        }}
      >
        {itemList}
      </Swiper>
    </>
  );
}

const EntryLinkButton = () => {
  return (
    <Link to="/entry" className={style["entry-link-button"]}>
      <div className={style["entry-link-area"]}>
        <p className={style["entry-link-text"]}>エントリーへ進む</p>
        <img src={redArrowCircle} className={style["entry-link-arrow"]} alt="icon" />
      </div>
    </Link>
  );
}

const RuleLinkButton = () => {
  return (
    <Link to="https://service.appbox.jp/kurasus/sus-katsu-list/" className={style["rule-link-button"]}>
      <div className={style["rule-link-area"]}>
        <p className={style["rule-link-text"]}>詳細はこちら</p>
        <img src={blueArrowCircle} className={style["entry-link-arrow"]} alt="icon" />
      </div>
    </Link>
  );
}
