import { useState, useEffect, useRef } from "react";
import style from "../style/formParts.module.css";

/**
 * input title
 */
export const InputTitle = ({ title, required = false }) => {
  return (
    <h3 className={style["input-title"]}>
      {title}
      {
        required && <RequiredIcon />
      }
    </h3>
  );
}

/**
 * required icon
 */
export const RequiredIcon = () => {
  return (
    <span className={style["required-icon"]}>
      必須
    </span>
  );
}

/**
 * input readonly
 */
export const InputReadOnly = ({ placeholder, defaultValue, fullWidth = false }) => {
  const className = fullWidth
    ? `${style["w-full"]} ${style["input-readonly"]}`
    : `${style["input-readonly"]}`;

  return (
    <>
      <p className={className}>{defaultValue}</p>
      <input
        type="text"
        defaultValue={defaultValue}
        readOnly
        style={{ display: "none" }}
      />
    </>
  );
}

/**
 * input text
 */
export const InputText = ({ placeholder, value, name, setValue, validError, maxlength = null, disabled = false }) => {
  const hasError = validError?.fieldErrorList && validError.fieldErrorList.some(fieldError => fieldError.field === name);

  return (
    <input
      type="text"
      className={`${style["input-text"]} ${hasError && style["error"]}`}
      name={name}
      placeholder={placeholder}
      value={value}
      maxLength={maxlength}
      onInput={setValue}
      disabled={disabled}
    />
  );
}

/**
 * input textarea
 */
export const InputTextarea = ({ placeholder, value, name, setValue, validError, maxlength = null, disabled = false }) => {
  const hasError = validError?.fieldErrorList && validError.fieldErrorList.some(fieldError => fieldError.field === name);

  // 改行目的のEnter押下を禁止
  window.document.onkeydown = function (evt) {
    const keyCode = evt.key;
    if (keyCode === 'Enter') {
      return false
    }
  }

  return (
    <textarea
      className={`${style["input-textarea"]} ${hasError && style["error"]}`}
      name={name}
      id="InputTextarea"
      placeholder={placeholder}
      value={value}
      maxLength={maxlength}
      onInput={setValue}
      disabled={disabled}
      rows="2"
    >
    </textarea>
  );
}

/**
 * input number
 */
export const InputNumber = ({ placeholder, value, name, setValue, validError, fullWidth = false, maxlength = 99, disabled }) => {
  const hasError = validError?.fieldErrorList && validError.fieldErrorList.some(fieldError => fieldError.field === name);

  return (
    <input
      type="number"
      name={name}
      className={`${fullWidth && style["w-full"]} ${hasError && style["error"]}`}
      placeholder={placeholder}
      value={value}
      maxLength={maxlength}
      disabled={disabled}
      onInput={(event) => {
        if (event.target.value.length > maxlength) {
          event.preventDefault();
          return;
        }
        setValue(event);
      }}
    />
  );
}

/**
 * select
 */
export const Select = ({
  name,
  options = [],
  placeholder = false,
  defaultValue,
  setValue,
  fullWidth = false,
  validError,
  disabled = false
}) => {

  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [placeholderDisabled, setPlaceholderDisabled] = useState(Boolean(defaultValue));

  useEffect(() => {
    setSelectedValue(defaultValue);
    setPlaceholderDisabled(Boolean(defaultValue));
  }, [defaultValue]);

  const onChange = (event) => {
    setValue(event);
    setSelectedValue(event.target.value);
    if (event.target.value !== "") {
      setPlaceholderDisabled(true);
    }
  }

  const hasError = validError?.fieldErrorList && validError.fieldErrorList.some(fieldError => fieldError.field === name);

  return (
    <span
      className={fullWidth
        ? `${style["select-wrap-w-full"]}`
        : `${style["select-wrap"]}`
      }
    >
      <select
        name={name}
        className={`${fullWidth && style["w-full"]} ${hasError && style["error"]}`}
        value={selectedValue === "" ? undefined : selectedValue}
        style={{
          textAlign: "left",
          color: selectedValue || placeholderDisabled ? "black" : "silver"
        }}
        disabled={disabled}
        onChange={onChange}
      >
        <option value="" disabled={placeholderDisabled} style={{ display: "none" }}>
          {placeholder}
        </option>
        {
          options.map((option, index) => (
            <option
              value={option.value}
              key={index}
            >
              {option.label}
            </option>
          ))
        }
      </select>
      <span className={style["select-arrow"]}></span>
    </span>
  );
}

/**
 * radio
 */
export const InputRadio = ({ options, name, defaultValue, setValue }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue])

  const onChange = (event) => {
    setValue(event);
    setSelectedValue(event.target.value);
  };

  return (
    <>
      {
        options.map((option, index) => (
          <label
            key={index}
          >
            <input
              type="radio"
              name={name}
              className={style["input-radio"]}
              value={option.value}
              checked={option.value === selectedValue}
              onChange={onChange}
            />
            <span className={style["input-radio-label"]}>{option.label}</span>
          </label>
        ))
      }
    </>
  );
}
