
export const getGraphData = (type, performanceData, targetData) => {
  let gasGraphColorList = []
  let gasPerformanceList = []
  // ガス使用量グラフの場合
  if (type == "gas") {
    performanceData.forEach((performance) => {
      // 正の数の場合
      if(performance >= 0) {
        gasGraphColorList.push("#55C5EB")
        gasPerformanceList.push(performance)
      // 負の数の場合
      } else {
        gasGraphColorList.push("#C6ECF9")
        gasPerformanceList.push(performance * -1)
      }
    })
  }

  const graphData = {
    labels: ["4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月", "1月", "2月", "3月"],
    datasets: [
      {
        data: type == "gas" ? gasPerformanceList : performanceData,
        label: "実績値",
        backgroundColor: type == "gas" ? gasGraphColorList : "#FFD801",
        borderRadius: 4,
        barPercentage: 0.6,
        order: 3,
      },
      {
        type: "line",
        label: "基準値",
        data: targetData,
        backgroundColor: "#E50012",
        borderColor: "#E50012",
        borderWidth: 1.5,
        fill: false,
        pointRadius: 2.5,
        order: 1,
      }
    ]
  };

  return graphData;
}

export const getGraphOptions = (maxScaleValue, scaleStep, energyUnit) => {
  const options = {
    layout: {
      padding: {
        right: 6,
        left: 6
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false
        },
        stacked: true,
        ticks: {
          padding: 0,
          color: "rgba(0,0,0,0.6)",
          font: {
            size: 8,
            weight: 600
          }
        }
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false
        },
        stacked: true,
        min: 0,
        max: maxScaleValue,
        ticks: {
          position: 'right',
          padding: 0,
          display: true,
          color: "rgba(0,0,0,0.6)",
          stepSize: scaleStep,
          font: {
            size: 10,
            weight: 500
          },
          callback: function (value) {
            return `${value.toLocaleString("ja-JP")}${energyUnit}`;
          }
        },
        afterUpdate: (chart, ticks) => {
          chart.width = 54;
          return ticks;
        },
      },
    },
    events: [],
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    animation: false,
    aspectRatio: 3 / 2,
    clip: false,
  };

  return options;
}
