import { usePostRequest } from "../../../hooks/usePostRequest";
import { checkClient } from "../utility/checkClient";
import style from "../style/confirmPopup.module.css";
import closeModal from "../image/close_modal.svg";

export const ConfirmPopup = ({
  isOpen,
  formData,
  responseData,
  closeConfirmPopup,
  openResultPopup,
  setValidError,
  isDetachedHouse
}) => {
  const API_URL = "/api/entry";
  const { post, isLoading } = usePostRequest({
    url: API_URL,
    postData: prepareFormData(formData, responseData, isDetachedHouse),
    method: responseData.isEntered === 0 ? "post" : "put",
    onSuccess() {
      closeConfirmPopup();
      openResultPopup();
    },
    onValidError(data) {
      closeConfirmPopup();
      updateValidError(data);
      setValidError(data);
    }
  });

  const onClick = () => {
    // post処理中にクリックされた場合にはイベントをキャンセルする
    if (isLoading) return;

    const checkClientResult = checkClient(formData, isDetachedHouse);
    // フロント側のバリデーションでエラーがあった場合
    if (checkClientResult !== true) {
      setValidError({
        errorCode: "VALID_ERROR_FRONT",
        errorMessage: "VALID_ERROR_FRONT",
        fieldErrorList: checkClientResult
      });
      closeConfirmPopup();
      return null;
    }
    // フロント側のバリデーションでエラーがなかった場合
    post();
  }

  return (
    <div className={`${style["popup"]} ${isOpen && style["open"]}`}>
      <div className={style["popup-inner"]}>
        <img
          src={closeModal}
          alt="close modal"
          className={style["close"]}
          onClick={() => {
            closeConfirmPopup()
          }}
        />
        <p className={style["text"]}>
          くらしのサス活事務局へ<br />
          ご入力の情報を送信します。<br />
          <br />
          送信後もいつでも変更いただけます。<br />
          <br />
          よろしければ<br />
          OKボタンを押してください。<br />
        </p>
        <a
          className={style["button"]}
          onClick={onClick}
        >
          OK
        </a>
      </div>
    </div >
  );
}

const updateValidError = (data) => {
  // 生年月日全体のエラーが返ってきた時
  if (data.fieldErrorList.some(item => item.field === "representativeBirthdate")) {
    data.fieldErrorList.push(
      {
        field: "representativeBirthYear",
        fieldErrorMessage: null
      },
      {
        field: "representativeBirthMonth",
        fieldErrorMessage: null
      },
      {
        field: "representativeBirthDay",
        fieldErrorMessage: null
      }
    );
  }
  // 入居予定日全体のエラーが返ってきた時
  if (data.fieldErrorList.some(item => item.field === "usageFromDate")) {
    data.fieldErrorList.push(
      {
        field: "usageFromYear",
        fieldErrorMessage: null
      },
      {
        field: "usageFromMonth",
        fieldErrorMessage: null
      },
      {
        field: "usageFromDay",
        fieldErrorMessage: null
      }
    );
  }
}

const prepareFormData = (formData, responseData, isDetachedHouse) => {
  let preparedFormData = formData;

  // 必須でない項目が空文字だった場合は null に変更する
  preparedFormData = {
    ...preparedFormData,
    residentsCount: formData.residentsCount === "" ? null : formData.residentsCount,
    childResidentsCount: formData.childResidentsCount === "" ? null : formData.childResidentsCount
  }

  // 配送先区分がエントリー物件情報と同じである場合、エントリー物件住所の値を配送先情報へ当てる
  if (formData.isDeliveryInfoEqualToEntryInfo === true || formData.isDeliveryInfoEqualToEntryInfo === "true") {
    preparedFormData = {
      ...preparedFormData,
      deliveryZip: `${formData.entryZip ?? ""}`,
      deliveryState: `${formData.entryState ?? ""}`,
      deliveryAddress: `${formData.entryAddress ?? ""}`,
      deliveryName: null
    }
  }

  // HTMLのフォームの値は全て文字列扱いのため、NumberとBooleanの値は型を変更する（IF設計参照）
  preparedFormData = {
    ...preparedFormData,
    representativeBirthYear: Number(formData.representativeBirthYear),
    representativeBirthMonth: Number(formData.representativeBirthMonth),
    representativeBirthDay: Number(formData.representativeBirthDay),
    usageClassificationId: Number(formData.usageClassificationId),
    isDeliveryInfoEqualToEntryInfo: formData.isDeliveryInfoEqualToEntryInfo === "true" || formData.isDeliveryInfoEqualToEntryInfo === true,
    energyVenderId: isDetachedHouse ? Number(formData.energyVenderId) : 3,
    usageDateFlg: formData.usageDateFlg === "true" || formData.usageDateFlg === true,
    usageFromYear: Number(formData.usageFromYear),
    usageFromMonth: Number(formData.usageFromMonth),
    usageFromDay: Number(formData.usageFromDay)
  }

  return preparedFormData;
}
