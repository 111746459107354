// 必須項目
const requiredFields = [
  "representativeLastName",
  "representativeFirstName",
  "representativeBirthYear",
  "representativeBirthMonth",
  "representativeBirthDay",
  "usageClassificationId",
  "isDeliveryInfoEqualToEntryInfo",
  "deliveryTell",
  "usageDateFlg",
];
// 「送付先郵便番号」「送付先都道府県」「送付先住所」「送付先宛名」
// isDeliveryInfoEqualToEntryInfoがfalseの場合に必須チェックを行う
const requiredFieldsDelivery = [
  "deliveryZip",
  "deliveryState",
  "deliveryAddress",
  "deliveryName"
];
// 戸建て物件の場合、エネルギー契約先IDに必須チェックを行う
const requiredFieldsDetachedHouce = [
  "energyVenderId",
];
//「利用開始予定日フラグ」がtrueの時、入居予定日「年」「月」「日」に必須チェックを行う
const requiredFieldsUsageDate = [
  "usageFromYear",
  "usageFromMonth",
  "usageFromDay",
];
// エントリー状況区分がエントリー完了以外の場合、エントリー物件住所「郵便番号」「都道府県」「住所」に必須チェックを行う
const requiredFieldsNotCompleteEntry = [
  "entryZip",
  "entryState",
  "entryAddress",
]
// 専有面積、延床面積が入力可能な状態である場合、「専有面積、延床面積」に必須チェックを行う
const requiredFieldsRoomSize = [
  "roomSize"
];


// 必須チェック処理
export const checkRequired = (formData, responseData, isDetachedHouse) => {
  // 必須項目の必須チェックを実施する
  for (let name of requiredFields) {
    if (formData[name] === "" || formData[name] === null) {
      // 必須項目のなかで未入力の項目があった場合、falseを返す
      return false;
    }
  }

  // 「お届け先」項目で「下記の住所へ送る」が選択されている場合、「送付先郵便番号」「送付先住所」「送付先宛名」についても必須チェックを実施する
  if (formData["isDeliveryInfoEqualToEntryInfo"] === false || formData["isDeliveryInfoEqualToEntryInfo"] === "false") {
    for (let name of requiredFieldsDelivery) {
      if (formData[name] === "" || formData[name] === null) {
        // 必須項目のなかで未入力の項目があった場合、falseを返す
        return false;
      }
    }
  }

  // 戸建て物件、かつ、「ガス契約先」が「東京ガス」の場合に、「お客様番号」の必須チェックを実施する
  const isTokyoGasVender = formData.energyVenderId == "1";
  if (isDetachedHouse && isTokyoGasVender) {
    if (formData["gasSupplyPointNo"] === "" || formData["gasSupplyPointNo"] === null) {
      return false;
    }
  }

  // 戸建て物件の場合
  if (isDetachedHouse) {
    for (let name of requiredFieldsDetachedHouce) {
      if (formData[name] === "" || formData[name] === null) {
        // 必須項目のなかで未入力の項目があった場合、falseを返す
        return false;
      }
    }
  }

  //「利用開始予定日フラグ」がtrueの場合
  if (formData["usageDateFlg"] === "true") {
    for (let name of requiredFieldsUsageDate) {
      if (formData[name] === "" || formData[name] === null) {
        // 必須項目のなかで未入力の項目があった場合、falseを返す
        return false;
      }
    }
  }

  // エントリー完了以外の場合
  if (formData["isEntered"] != 1) {
    for (let name of requiredFieldsNotCompleteEntry) {
      if (formData[name] === "" || formData[name] === null) {
        // 必須項目のなかで未入力の項目があった場合、falseを返す
        return false;
      }
    }
  }
  // 専有面積：延床面積が入力可能の場合
  if (responseData !== null && responseData.roomSize === null) {
    for (let name of requiredFieldsRoomSize) {
      if (formData[name] === "" || formData[name] === null) {
        // 必須項目のなかで未入力の項目があった場合、falseを返す
        return false;
      }
    }
  }

  // 必須項目が全て入力されている場合、trueを返す
  return true;
}
