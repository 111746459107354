import { Section } from "./Section";
import {
  InputTitle,
  InputText,
  InputTextarea,
  InputNumber,
  InputReadOnly,
  InputRadio,
  Select
} from "./FormParts";
import { prefecturesMaster } from "../utility/prefecturesMaster";
import style from "../style/style.module.css";

export const ContactSection = ({ isEntered, deliveryEmail, formData, setValue, validError }) => {
  return (
    <Section title="連絡先・特典のお届け先">
      <InputTitle
        title="メールアドレス"
      />
      <p className={style["text-blue"]}>代表者のアドレス宛に事務局からの連絡、デジタル特典提供を行う場合があります。</p>
      {
        isEntered === 0 &&
        <InputReadOnly
          defaultValue={deliveryEmail}
          fullWidth
        />
      }
      <InputTitle
        title="お届け先"
        required
      />
      <div className={style["radio-delivery-info-wrap"]}>
        <InputRadio
          options={[
            { value: "true", label: "エントリー物件住所へ送る" },
            { value: "false", label: "下記の住所へ送る" }
          ]}
          defaultValue={String(formData.isDeliveryInfoEqualToEntryInfo ?? true)}
          name="isDeliveryInfoEqualToEntryInfo"
          setValue={setValue}
        />
      </div>
      <div className={style["input-zip-wrap"]}>
        <span className={style["label"]}>〒</span>
        <InputNumber
          value={formData.deliveryZip ?? ""}
          name="deliveryZip"
          maxlength={7}
          placeholder="1030022"
          setValue={setValue}
          validError={validError}
          disabled={isTrue(formData.isDeliveryInfoEqualToEntryInfo)}
        />
      </div>
      <div className={style["input-address-wrap"]}>
        <span className={style["label"]}>住所</span>
        <Select
          options={prefecturesMaster()}
          name="deliveryState"
          validError={validError}
          setValue={setValue}
          placeholder="ーーー"
          disabled={isTrue(formData.isDeliveryInfoEqualToEntryInfo)}
          defaultValue={formData.deliveryState ?? ""}
        />
      </div>
      <div className={style["input-address-wrap"]}>
        <span className={style["label"]}></span>
        <InputTextarea
          value={formData.deliveryAddress ?? ""}
          name="deliveryAddress"
          maxlength={500}
          placeholder="中央区日本橋室町3-2-1"
          setValue={setValue}
          validError={validError}
          disabled={isTrue(formData.isDeliveryInfoEqualToEntryInfo)}
        />
      </div>
      <div className={style["input-addressee-wrap"]}>
        <span className={style["label"]}>宛名</span>
        <InputText
          value={formData.deliveryName ?? ""}
          name="deliveryName"
          maxlength={128}
          setValue={setValue}
          fullWidth
          validError={validError}
          disabled={isTrue(formData.isDeliveryInfoEqualToEntryInfo)}
        />
      </div>
      <InputTitle
        title="お届け先電話番号"
        setValue={setValue}
        required
      />
      <InputNumber
        value={formData.deliveryTell ?? ""}
        name="deliveryTell"
        maxlength={11}
        placeholder="0312345678"
        setValue={setValue}
        fullWidth
        validError={validError}
      />
    </Section>
  );
}

const isTrue = (value) => {
  return value === true || value === "true";
}
