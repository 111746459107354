import { Co2Graph } from "./Co2Graph";
import { Co2Table } from "./Co2Table";
import { Graph } from "./Graph";
import { Table } from "./Table";
import style from "../style/main.module.css";
import arrowIcon from "../image/icon_year_select.svg";

export const Main = ({
  year,
  maxYear,
  minYear,
  decrementYear,
  incrementYear,
  energyData,
  isEntered,
  isAllElectric
}) => {

  const isEmptyObject = (object) => {
    // objectかの判定
    if (typeof object !== 'object' || object === null || Array.isArray(object)) {
      return false;
    }
    // 空かどうかの判定
    return Object.keys(object).length === 0;
  }

  const electricGraphWaitingTextFlag
    = isEmptyObject(energyData.electricPerformance)
    && isEmptyObject(energyData.electricTarget)
    && isEntered === 2;

  const gasGraphWaitingTextFlag
    = isEmptyObject(energyData.gasPerformance)
    && isEmptyObject(energyData.gasTarget)
    && isEntered === 2;

  const co2GraphWaitingTextFlag
    = isEmptyObject(energyData.co2Performance)
    && isEmptyObject(energyData.co2Target)
    && isEntered === 2;

  return (
    <div className={style["main"]}>
      <span className={style["line-graduation"]}></span>
      <h2 className={style["graph-title"]}>
        想定CO&#8322;排出量
        <span className={style["co2-info"]}>※実際のCO&#8322;排出量と異なります。</span>
      </h2>
      <Co2Graph
        year={year}
        maxYear={maxYear}
        minYear={minYear}
        incrementYear={incrementYear}
        decrementYear={decrementYear}
        evaluationStatusIconList={energyData.evaluationStatusIconList?.[year] ?? []}
        co2PerformanceData={energyData.co2Performance?.[year] ?? []}
        co2TargetData={energyData.co2Target?.[year] ?? []}
        gasCo2PerformanceData={energyData.gasCo2Performance?.[year] ?? []}
        electricCo2PerformanceData={energyData.electricCo2Performance?.[year] ?? []}
        waiting={co2GraphWaitingTextFlag}
      />
      <Co2Table
        year={year}
        co2Performance={energyData.co2Performance?.[year] ?? []}
        co2Target={energyData.co2Target?.[year] ?? []}
        pointList={energyData.pointList?.[year] ?? []}
        evaluationStatusIconList={energyData.evaluationStatusIconList?.[year] ?? []}
      />
      <h2 className={style["graph-title"]}>電気使用量</h2>
      <Graph
        type="electric"
        energyUnit="kWh"
        year={year}
        maxYear={maxYear}
        minYear={minYear}
        incrementYear={incrementYear}
        decrementYear={decrementYear}
        performanceData={energyData.electricPerformance?.[year] ?? []}
        targetData={energyData.electricTarget?.[year] ?? []}
        defaultMaxScale={400}
        waiting={electricGraphWaitingTextFlag}
      />
      <Table
        year={year}
        energyName="電気"
        energyUnit="kWh"
        energyPerformanceData={energyData.electricPerformance?.[year] ?? []}
        energyTargetData={energyData.electricTarget?.[year] ?? []}
        co2PerformanceData={energyData.electricCo2Performance?.[year] ?? []}
      />
      {/* オール電化の場合は表示しない */}
      {isAllElectric ? (
        <div>
        </div>
      ) : (
        <div>
          <h2 className={style["graph-title"]}>ガス使用量</h2>
          <Graph
            type="gas"
            energyUnit="㎥"
            year={year}
            maxYear={maxYear}
            minYear={minYear}
            incrementYear={incrementYear}
            decrementYear={decrementYear}
            performanceData={energyData.gasPerformance?.[year] ?? []}
            targetData={energyData.gasTarget?.[year] ?? []}
            defaultMaxScale={20}
            waiting={gasGraphWaitingTextFlag}
          />
          <Table
            year={year}
            energyName="ガス"
            energyUnit="㎥"
            energyPerformanceData={energyData.gasPerformance?.[year] ?? []}
            energyTargetData={energyData.gasTarget?.[year] ?? []}
            co2PerformanceData={energyData.gasCo2Performance?.[year] ?? []}
          />
        </div>
      )}
    </div>
  );
}