import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Header } from "./component/Header";
import { Activity } from "./component/Activity";
import { ActivityBeforeEntryComplete } from "./component/ActivityBeforeEntryComplete";
import { Main } from "./component/Main";
import { useYear } from "./hooks/useYear";
import { useGetRequest } from "../../hooks/useGetRequest";
import { convertEnergyData } from "./utility/convertEnergyData.js";

/**
 * エネルギー画面
 */
export const Page = () => {
  // 年の選択
  const { year, setYear, incrementYear, decrementYear } = useYear();
  // エネルギーデータ（グラフ、テーブルの表示情報）
  const [energyData, setEnergyData] = useState([]);
  // アクティビティエリアの表示情報
  const [nearestActivity, setNearestActivity] = useState();

  // API 呼び出し
  // クエリ文字列からroomId,developerIdを取得して、内部API実行時に付与する
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("roomId");
  const developerId = searchParams.get("developerId");

  let params = {};
  if (roomId !== null) {
    params.roomId = roomId;
  }
  if (developerId !== null) {
    params.developerId = developerId;
  }

  const API_URL = "/api/energy";
  const { data, error, isLoading } = useGetRequest(API_URL, params);

  useEffect(() => {
    if (data === null) return; // データフェッチ中(dataがnullの時)は処理を中断する

    const {
      electricalUsageList,
      gasUsageList,
      earnPointList,
      co2UsageList
    } = data;

    const {
      energyData,
      initialYear,
      maxYear,
      minYear,
      nearestActivity
    } = convertEnergyData({ electricalUsageList, gasUsageList, earnPointList, co2UsageList });

    setEnergyData(energyData);

    setYear({
      selected: data.isEntered === 2 ? data.fiscalYear : initialYear,
      max: maxYear,
      min: minYear
    });

    setNearestActivity(nearestActivity);
  }, [data]);

  if (isLoading || error) {
    return null;
  }

  return (
    <>
      <Header />
      {
        data.isEntered === 1
          ?
          <Activity
            month={nearestActivity?.month ?? null}
            co2Performance={nearestActivity?.co2Performance ?? 0}
            co2Reduction={nearestActivity?.co2Reduction ?? 0}
            point={nearestActivity?.point ?? 0}
            evaluationStatusIcon={nearestActivity?.evaluationStatusIcon ?? null}
          />
          : <ActivityBeforeEntryComplete />
      }
      <Main
        year={year.selected}
        maxYear={year.max}
        minYear={year.min}
        incrementYear={incrementYear}
        decrementYear={decrementYear}
        energyData={energyData}
        isEntered={data?.isEntered}
        isAllElectric={data.gasUsageList.length !== 0 && data.gasUsageList !== undefined && data.gasUsageList[0].target == 9999 ? true : false}
      />
    </>
  );
}