import style from "../style/confirmPopup.module.css";
import closeModal from "../image/close_modal.svg";

export const ConfirmPopup = ({
  isOpen,
  closeConfirmPopup
}) => {
  return (
    <div className={`${style["popup"]} ${isOpen && style["open"]}`}>
      <div className={style["popup-inner"]}>
        <img
          src={closeModal}
          alt="close modal"
          className={style["close"]}
          onClick={() => {
            closeConfirmPopup()
          }}
        />
        <p className={style["text"]}>
          お引越前・直後の方へ<br /><br />
          このお手続きは、初回電気料金の請求連絡があった後に開始してください。
        </p>
        <a className={style["button"]} href="https://reg31.smp.ne.jp/regist/is?SMPFORM=qdoj-ljlfqb-99f3701207d8e0c6a3a264249bcb1e5b">OK</a>
      </div>
    </div >
  );
}
