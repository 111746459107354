import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { createOptions } from "./utility/createOptions";
import { checkRequired } from "./utility/checkRequired";
import { mansionType } from "./utility/mansionType";
import { ConfirmPopup } from "./component/ConfirmPopup";
import { ResultPopup } from "./component/ResultPopup";
import { Header } from "./component/Header";
import { Error } from "./component/Error";
import { BasicInfoSection } from "./component/BasicInfoSection";
import { ContactSection } from "./component/ContactSection";
import { EnergySection } from "./component/EnergySection";
import { EnergySectionAfterConnected } from "./component/EnergySectionAfterConnected";
import { ButtonArea } from "./component/ButtonArea";
import { usePopupStatus } from "../../hooks/usePopupStatus";
import { useGetRequest } from "../../hooks/useGetRequest";

/**
 * エントリー画面
 */
export const Page = () => {

  // 内部APIの実行
  // クエリ文字列からroomId,developerIdを取得して、内部API実行時に付与する
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("roomId");
  const developerId = searchParams.get("developerId");

  let params = {};
  if (roomId !== null) {
    params.roomId = roomId;
  }
  if (developerId !== null) {
    params.developerId = developerId;
  }

  const API_URL = "/api/entry";
  const { data, error, isLoading } = useGetRequest(API_URL, params);

  // フォームデータをstateで管理する
  const [formData, setFormData] = useState({});

  // APIレスポンスデータが更新されたときに、フォームデータを更新する
  useEffect(() => {
    if (data === null) return; // データフェッチ中(dataがnullの時)は処理を中断する

    setFormData((formData) => {
      // 更新後のフォームデータを既存のフォームデータにマージする
      let newFormData = {
        ...formData,
        ...data
      }
      // ラジオボタンの初期値設定
      if (newFormData.isDeliveryInfoEqualToEntryInfo === null) {
        newFormData.isDeliveryInfoEqualToEntryInfo = "true";
      }
      if (newFormData.usageClassificationId === null) {
        newFormData.usageClassificationId = "1";
      }
      // 配送先情報がエントリー情報と同じ場合、配送先情報(郵便番号、都道府県、住所、宛名)を空欄で表示する
      if (newFormData.isDeliveryInfoEqualToEntryInfo === "true" || newFormData.isDeliveryInfoEqualToEntryInfo === true) {
        newFormData = {
          ...newFormData,
          deliveryZip: "",
          deliveryState: "",
          deliveryAddress: "",
          deliveryName: ""
        }
      }
      // 利用開始予定日フラグの初期値設定
      if (newFormData.usageDateFlg === undefined || newFormData.usageDateFlg === null) {
        newFormData.usageDateFlg = "false";
      }
      // 入居予定日が入力されている場合、利用開始予定日フラグをtrue（未入居）に変更する
      if (newFormData.usageFromYear !== null && newFormData.usageFromMonth !== null && newFormData.usageFromDay !== null) {
        newFormData.usageDateFlg = "true";
      }
      // マンションかつかつエントリー物件住所未登録の場合、エントリー物件住所の初期値を設定する
      if (!mansionType(newFormData.mansionId).isDetachedHouse && !newFormData.entryZip && !newFormData.entryState && !newFormData.entryAddress) {
        newFormData.entryZip = newFormData.postalCode ?? "";
        newFormData.entryState = newFormData.state ?? "";
        newFormData.entryAddress = `${newFormData.city ?? ""}${newFormData.addressLine1 ?? ""}${newFormData.addressLine2 ?? ""}${newFormData.mansionNameJapanese ?? ""}${newFormData.buildingNameJapanese ?? ""}${newFormData.roomName ?? ""}`;
      }
      return newFormData;
    });
  }, [data]);

  // 送信データの更新
  const setValue = (event) => {
    setFormData((formData) => ({
      ...formData,
      [event.target.name]: event.target.value
    }));
  }

  // APIレスポンスデータから作成する、セレクトボックス、ラジオボタンのJSONデータをstateで管理する
  const [usageClassificationIdOptions, setUsageClassificationIdOptions] = useState([]);
  const [energyVenderIdOptions, setEnergyVenderIdOptions] = useState([]);

  // APIレスポンスデータが更新されたときに、セレクトボックス、ラジオボタンのJSONデータ更新する
  useEffect(() => {
    if (data === null) return; // データフェッチ中(dataがnullの時)は処理を中断する

    setEnergyVenderIdOptions(createOptions(data["energyVenderList"]));
    setUsageClassificationIdOptions(createOptions(data["usageClassificationList"]));
  }, [data]);

  // 必須チェックの結果をstateで管理する
  const [isRequiredValid, setIsRequiredValid] = useState(false);

  // フォームデータに変更があった時、必須チェックを実施する
  useEffect(() => {
    setIsRequiredValid(
      checkRequired(formData, data, mansionType(formData.mansionId).isDetachedHouse)
    );
  }, [formData])

  // 確認モーダルの管理
  const { isOpen: isOpenConfirmPopup, open: openConfirmPopup, close: closeConfirmPopup } = usePopupStatus();
  // 完了モーダルの管理
  const { isOpen: isOpenResultPopup, open: openResultPopup } = usePopupStatus();

  // バリデーション処理
  const [validError, setValidError] = useState({});
  useEffect(() => {
    setValidError({});
  }, [])

  if (isLoading || error) {
    return null;
  }

  return (
    <>
      <ConfirmPopup
        isOpen={isOpenConfirmPopup}
        formData={formData}
        responseData={data}
        closeConfirmPopup={closeConfirmPopup}
        openResultPopup={openResultPopup}
        setValidError={setValidError}
        isDetachedHouse={mansionType(formData.mansionId).isDetachedHouse}
      />
      <ResultPopup
        isOpen={isOpenResultPopup}
        responseData={data}
        isDetachedHouse={mansionType(formData.mansionId).isDetachedHouse}
        isMansionBundleCall={mansionType(formData.mansionId).isMansionBundleCall}
        isMansionIndividualCall={mansionType(formData.mansionId).isMansionIndividualCall}
      />
      <Header />
      {
        validError?.fieldErrorList && <Error fieldErrorList={validError.fieldErrorList} />
      }
      <BasicInfoSection
        postalCode={data.postalCode ?? ""}
        state={data.state ?? ""}
        city={data.city ?? ""}
        addressLine1={data.addressLine1 ?? ""}
        addressLine2={data.addressLine2 ?? ""}
        mansionNameJapanese={data.mansionNameJapanese ?? ""}
        buildingNameJapanese={data.buildingNameJapanese ?? ""}
        roomName={data.roomName ?? ""}
        formData={formData}
        setValue={setValue}
        usageClassificationIdOptions={usageClassificationIdOptions}
        validError={validError}
        isDetachedHouse={mansionType(formData.mansionId).isDetachedHouse}
        isEntered={data.isEntered}
        responseData={data}
      />
      <ContactSection
        isEntered={data.isEntered}
        deliveryEmail={data.deliveryEmail ?? ""}
        formData={formData}
        setValue={setValue}
        validError={validError}
      />
      {/* {
        data.isEntered === 1
          ? <EnergySectionAfterConnected setValue={setValue} />
          : <EnergySection formData={formData} setValue={setValue} energyVenderIdOptions={energyVenderIdOptions} validError={validError} isEntered={data.isEntered} />
      } */}
      {
        mansionType(formData.mansionId).isDetachedHouse &&
        <EnergySection
          formData={formData}
          setValue={setValue}
          energyVenderIdOptions={energyVenderIdOptions}
          validError={validError}
          isEntered={data.isEntered}
        />
      }
      <ButtonArea
        isRequiredValid={isRequiredValid}
        openConfirmPopup={openConfirmPopup}
      />
    </>
  );
}
