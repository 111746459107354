import { Flow } from "./component/Flow";
import { ButtonArea } from "./component/ButtonArea";
import { ConfirmPopup } from "./component/ConfirmPopup";
import { usePopupStatus } from "../../hooks/usePopupStatus";
import style from "./style/style.module.css";

/**
 * 電力データ提供手続き画面
 */
export const Page = () => {

  // 確認モーダルの管理
  const { isOpen: isOpenConfirmPopup, open: openConfirmPopup, close: closeConfirmPopup } = usePopupStatus();

  return (
    <>
      <div className={style["page-body"]}>
        <ConfirmPopup
          isOpen={isOpenConfirmPopup}
          closeConfirmPopup={closeConfirmPopup}
        />
        <Flow/>
        <ButtonArea
          openConfirmPopup={openConfirmPopup}
        />
        <div className={style["note-area"]}>
          <p>申請することで、手続きが完了します。手続き途中で終わると、この画面からやり直しになります。</p>
        </div>
      </div>
    </>
  );
}