export const getMaxScaleValue = (defaultMaxScale, data1, data2) => {
  // performanceがマイナスの場合、絶対値に変換する
  let processedData = []
  data2.forEach((rowData) => {
    // 正の数の場合
    if(rowData >= 0) {
      processedData.push(rowData)
    // 負の数の場合
    } else {
      processedData.push(rowData * -1)
    }
  })

  const scaleCalculationData = [
    ...data1.filter(value => value !== null),
    ...processedData.filter(value => value !== null)
  ];
  const maxDataValue = Math.max(...scaleCalculationData);
  const maxScaleValue = maxDataValue === -Infinity || maxDataValue === 0
    ? defaultMaxScale
    : Math.round(maxDataValue / 0.75 / 4) * 4;

  return maxScaleValue;
}
