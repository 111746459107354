/**
 * APIから取得してきたポイント履歴リストを年ごとにグルーピングして返す
 * @param {array} List - APIから取得してきたポイント履歴リスト 
 * @return {Object} 
 * @property {array} groupedList - 年ごとにグルーピングされたポイント履歴リスト
 * @property {number} maxYear - ポイント履歴の存在する最大年
 * @property {number} minYear - ポイント履歴の存在する最小年
 */
export const groupListByYear = (List) => {
  const groupedList = {};
  const years = [];

  List.forEach(item => {
    const year = item.year;
    if (!groupedList[year]) {
      groupedList[year] = [];
      years.push(year);
    }
    groupedList[year].push(item);
  });

  return {
    groupedList,
    maxYear: years.length > 0 ? Math.max(...years) : getNowYear(),
    minYear: years.length > 0 ? Math.min(...years) : getNowYear()
  }
}

// ポイント獲得履歴がない場合、現在の年度を表示する
const getNowYear = () => {
  // 現在時刻取得
  const nowDate = new Date();
  // 1~3月の場合は-1して年度に変換する
  if (nowDate.getMonth() >= 0 && nowDate.getMonth() <= 2) {
    return nowDate.getFullYear() - 1;
  } else {
    return nowDate.getFullYear();
  }
}