import { Link } from "react-router-dom";
import { Section } from "./Section";
import {
  InputTitle,
  InputText,
  InputNumber,
  InputReadOnly,
  Select
} from "./FormParts";
import style from "../style/style.module.css";

export const EnergySection = ({ formData, setValue, energyVenderIdOptions, validError, isEntered }) => {
  const isTokyoGasVender = formData.energyVenderId == "1";
  const isOtherVender = formData.energyVenderId == "3";
  const isEntryCompleted = isEntered == 1;

  return (
    <Section title="ガス契約">
      <div className={style["energy-vender-id-wrap"]}>
        {/* <InputTitle
          title="予定契約先"
          required
        />
        <p className={style["text-blue"]}>選択肢にないエネルギー供給会社と契約された場合は、サス活へ参加いただけません。</p> */}
        <Select
          name="energyVenderId"
          options={energyVenderIdOptions}
          placeholder="選択してください"
          defaultValue={formData.energyVenderId ?? ""}
          setValue={setValue}
          fullWidth
          validError={validError}
          disabled={isEntryCompleted}
        />
      </div>
      {
        isOtherVender && !isEntryCompleted &&
        <p className={style["text-blue"]}>東京ガス、ファミリーネット・ジャパン ガス以外のガスをお申し込みの場合は、当社が定めるみなし月次使用量を反映いたします</p>
      }
      {
        isTokyoGasVender && !isEntryCompleted &&
        <>
          <p className={style["text-blue"]}>お客さま番号をご入力ください</p>
          <p className={style["input-gasSupplyPointNo-info"]}>
            ・myTOKYOGASのマイページで確認ができます<br />
            ・ガスの開栓作業後に作業員がお客様にお渡しする「ガスご使用開始に伴う作業結果のお知らせ」内の「メーター設置場所番号」がお客さま番号となります<br />
            ・お客さま番号（例：1111-222-3333）は、ハイフンを除いてご入力ください
          </p>
          <InputTitle
            title="お客さま番号"
            required
          />
          <div className={style["input-gasSupplyPointNo-wrap"]}>
            <InputText
              setValue={setValue}
              name="gasSupplyPointNo"
              validError={validError}
              placeholder="11112223333(ハイフン無)"
              value={formData["gasSupplyPointNo"] ?? ""}
            />
          </div>
        </>
      }
      {/* <InputTitle
        title="省エネポイント反映"
      />
      <p className={`${style["text-blue"]} ${style["mb-11"]}`}>エネルギー供給会社の初回検針の後、開始します。</p>
      <InquiryInfo isEntered={isEntered} /> */}
      {
        isEntryCompleted &&
        <EnergySectionAfterConnected />
      }
    </Section>
  );
}

// const InquiryInfo = ({ isEntered }) => {
//   if (isEntered === 0) {
//     return (
//       <p>※エネルギー供給会社の初回検針後、3か月経過しても省エネポイントが反映されない場合は、事務局へお問い合わせください。</p>
//     );
//   }
//   return (
//     <p>※エネルギー供給会社の初回検針後、3か月経過しても省エネポイントが反映されない場合は、<Link to="/secretariatContact">事務局へお問い合わせ</Link>ください。</p>
//   );
// }

const EnergySectionAfterConnected = () => {
  return (
    <>
      <p className={style["mb-23"]}>※エントリー後に契約先を変更する場合は、<Link to={"/secretariatContact"}>事務局へご一報ください</Link>。エントリー引き継ぎの確認をいたします。</p>
      <p>※ご退去の場合は、退去の手続きをお願いいたします。</p>
    </>
  );
}
